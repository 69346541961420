window._ = require('lodash');

if (document.querySelector(".js-cookie-consent")) {
    const cookie_consent = document.querySelector(".js-cookie-consent");
    const cookie_consent_agree = document.querySelector("a.js-cookie-consent-agree");
    cookie_consent_agree.addEventListener("click", function(e) {
        cookie_consent.classList.add('d-none');
    });
}
if (document.querySelector('section#parallax .shape-one')) {
    const shape_one = document.querySelector('section#parallax .shape-one');
    window.addEventListener('scroll', () => {
        var value = window.scrollY;
        shape_one.style.backgroundPositionY = value * 0.15 + 'px';
    });
}